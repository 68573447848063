import revive_payload_client_8dSUj_9mK7cgRYVYCDZLVj3uodV3L8uvU4UdU9KdVOo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_drizzle-orm@0.41.0_gel@_61c9ecf1814f04a87d7b7d8feeca27b3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CFPkrzntzR8xv9FQmt6y1rrLVHwSDQHwOSSXHS0LrLI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_drizzle-orm@0.41.0_gel@_61c9ecf1814f04a87d7b7d8feeca27b3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_i5hqIkQxEB2zd65O_nhqRU6MruN4D1EiHpVwFKddjzY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_drizzle-orm@0.41.0_gel@_61c9ecf1814f04a87d7b7d8feeca27b3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yJ1hiReZdiRFuegErkgd_f3WBt6n0aN9ZMmdnwe9Qso from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_drizzle-orm@0.41.0_gel@_61c9ecf1814f04a87d7b7d8feeca27b3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_umjzzkkI0ogYYRyhm5OKaUWeK_d0vjFoYJVEEg_RXu8 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_drizzle-orm@0.41.0_gel@_61c9ecf1814f04a87d7b7d8feeca27b3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NLCLKKfLwp2b7XXlkXCS02pCxW3NCnuY7usc2Fqm10g from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_drizzle-orm@0.41.0_gel@_61c9ecf1814f04a87d7b7d8feeca27b3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_h3qOLUDWhr11PluFcpkg1VVnbYNpx78O_Y7FZcUMmvY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_drizzle-orm@0.41.0_gel@_61c9ecf1814f04a87d7b7d8feeca27b3/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_VFnfhCMkNZNp_1qtQvK6g7FBVdCGz4HoRH2JbPUIupA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_drizzle-orm@0.41.0_gel@_61c9ecf1814f04a87d7b7d8feeca27b3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/opt/buildhome/repo/packages/web/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_fu_Q1ng1uKGydqFMEgMoCj_IWV7FwIkbDkJset29gh8 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_drizzle-orm@0.41.0_gel@_61c9ecf1814f04a87d7b7d8feeca27b3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_6Z4vW7S9aX_q2svWbGBc_X2b5QbQdkNmvzr_3kqqCd0 from "/opt/buildhome/repo/packages/web/node_modules/.cache/nuxt/.nuxt/unocss.mjs";
export default [
  revive_payload_client_8dSUj_9mK7cgRYVYCDZLVj3uodV3L8uvU4UdU9KdVOo,
  unhead_CFPkrzntzR8xv9FQmt6y1rrLVHwSDQHwOSSXHS0LrLI,
  router_i5hqIkQxEB2zd65O_nhqRU6MruN4D1EiHpVwFKddjzY,
  payload_client_yJ1hiReZdiRFuegErkgd_f3WBt6n0aN9ZMmdnwe9Qso,
  navigation_repaint_client_umjzzkkI0ogYYRyhm5OKaUWeK_d0vjFoYJVEEg_RXu8,
  check_outdated_build_client_NLCLKKfLwp2b7XXlkXCS02pCxW3NCnuY7usc2Fqm10g,
  view_transitions_client_h3qOLUDWhr11PluFcpkg1VVnbYNpx78O_Y7FZcUMmvY,
  chunk_reload_client_VFnfhCMkNZNp_1qtQvK6g7FBVdCGz4HoRH2JbPUIupA,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_fu_Q1ng1uKGydqFMEgMoCj_IWV7FwIkbDkJset29gh8,
  unocss_6Z4vW7S9aX_q2svWbGBc_X2b5QbQdkNmvzr_3kqqCd0
]